import Co2Icon from '@mui/icons-material/Co2';
import CompressIcon from '@mui/icons-material/Compress';
import ThermostatAutoIcon from '@mui/icons-material/ThermostatAuto';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import { Box, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';

import Histogram from '../../../common/components/histogram';
import { DeviceReport } from '../../../common/models/machineReport';
import { MachineDetailService } from '../services/machineService';

export type MachineInfoProps = {
  mode: 'machine-udl' | 'machine-co2';
};

const MachineHistogram = ({ mode }: MachineInfoProps) => {
  const [reports, setReports] = useState<DeviceReport[]>([]);

  const service = new MachineDetailService();

  useEffect(() => {
    if (reports.length > 0) return;

    service.getReportsByType(mode).then((res) => {
      if (res instanceof Error) return;

      console.log(res);

      setReports(res);
    });
  }, []);

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={{ xs: 1, md: 1 }}>
      {mode === 'machine-co2' && (
        <Grid md={4} xs={8}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              boxSizing: 'border-box',
              display: 'flex',
              justifyItems: 'start',
              justifyContent: 'start',
              width: '100%',
              height: '100%',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px'
              }}
            >
              <ThermostatAutoIcon />
              <Typography variant="h6" component="p">
                <strong>Temperatura en &deg;C</strong>
              </Typography>
            </Box>
            <Histogram
              isTimeBased={true}
              yValueFormatter={(t) => `${t?.toFixed(2) ?? 0}°C`}
              x={reports.map((report) => new Date(Date.parse(report.creationDate)))}
              y={reports.map((report) => report.temperature.average)}
            />
          </Paper>
        </Grid>
      )}
      {mode === 'machine-co2' && (
        <Grid md={4} xs={8}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              boxSizing: 'border-box',
              display: 'flex',
              justifyItems: 'start',
              justifyContent: 'start',
              width: '100%',
              height: '100%',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px'
              }}
            >
              <WaterDropIcon />
              <Typography variant="h6" component="p">
                <strong>Humedad</strong>
              </Typography>
            </Box>
            <Histogram
              isTimeBased={true}
              yValueFormatter={(t) => `${t?.toFixed(2) ?? 0}%RH`}
              x={reports.map((report) => new Date(Date.parse(report.creationDate)))}
              y={reports.map((report) => report.humidity.average)}
            />
          </Paper>
        </Grid>
      )}
      {mode === 'machine-co2' && (
        <Grid md={4} xs={8}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              boxSizing: 'border-box',
              display: 'flex',
              justifyItems: 'start',
              justifyContent: 'start',
              width: '100%',
              height: '100%',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px'
              }}
            >
              <Co2Icon />
              <Typography variant="h6" component="p">
                <strong>Nivel CO2</strong>
              </Typography>
            </Box>
            <Histogram
              isTimeBased={true}
              yValueFormatter={(t) => `${t?.toFixed(2) ?? 0}ppm`}
              x={reports.map((report) => new Date(Date.parse(report.creationDate)))}
              y={reports.map((report) => report.co2.average)}
            />
          </Paper>
        </Grid>
      )}
      {mode === 'machine-co2' && (
        <Grid md={4} xs={8}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              boxSizing: 'border-box',
              display: 'flex',
              justifyItems: 'start',
              justifyContent: 'start',
              width: '100%',
              height: '100%',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px'
              }}
            >
              <CompressIcon />
              <Typography variant="h6" component="p">
                <strong>Nivel de Presión</strong>
              </Typography>
            </Box>
            <Histogram
              isTimeBased={true}
              yValueFormatter={(t) => `${t?.toFixed(2) ?? 0}hPa`}
              x={reports.map((report) => new Date(Date.parse(report.creationDate)))}
              y={reports.map((report) => report.pressure.average)}
            />
          </Paper>
        </Grid>
      )}
      {mode === 'machine-udl' && (
        <Grid md={4} xs={8}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              boxSizing: 'border-box',
              display: 'flex',
              justifyItems: 'start',
              justifyContent: 'start',
              width: '100%',
              height: '100%',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px'
              }}
            >
              <CompressIcon />
              <Typography variant="h6" component="p">
                <strong>Distancia</strong>
              </Typography>
            </Box>
            <Histogram
              isTimeBased={true}
              yValueFormatter={(t) => `${t?.toFixed(2) ?? 0}m`}
              x={reports.map((report) => new Date(Date.parse(report.creationDate)))}
              y={reports.map((report) => report.distance.average)}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
export default MachineHistogram;
