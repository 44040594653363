import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import HomeIcon from '@mui/icons-material/Home';
import React from 'react';

import PersonSVG from '../../assets/construction-site-flatline.svg';
import MapaSVG from '../../assets/map.svg';
import PlacesSVG from '../../assets/places.svg';
import MachinesSVG from '../../assets/production-flatline.svg';
import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import NavigationMenu, { INavigationMenuItem } from '../../common/components/navigationMenu';

const menuItems: INavigationMenuItem[] = [
  {
    name: 'Mapa en Tiempo Real',
    to: '/monitoreo/mapa',
    types: ['COMMON_USER', 'ADMIN'],
    svg: <img src={MapaSVG} alt="Mapa en Tiempo Real" width={250} />
  },
  {
    name: 'Colaboradores',
    to: '/monitoreo/colaboradores',
    types: ['COMMON_USER', 'ADMIN'],
    svg: <img src={PersonSVG} alt="Colaboradores" width={250} />
  },
  {
    name: 'Equipos',
    to: '/monitoreo/equipos',
    types: ['COMMON_USER', 'ADMIN'],
    svg: <img src={MachinesSVG} alt="Equipos" width={250} />,
    hidden: false
  },
  {
    name: 'Zonas',
    to: '/monitoreo/zonas',
    types: ['COMMON_USER', 'ADMIN'],
    svg: <img src={PlacesSVG} alt="Zonas" width={250} />
  }
];
const TrackingMenu = () => {
  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      Icon: <HomeIcon color="secondary" />,
      text: 'Home',
      path: '/'
    },
    {
      Icon: <GpsFixedIcon color="primary" />,
      text: 'Monitoreo',
      path: '/monitoreo'
    }
  ];

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Menú de Monitoreo" />
      <NavigationMenu items={menuItems} />
    </div>
  );
};

export default TrackingMenu;
