import CachedIcon from '@mui/icons-material/Cached';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import HomeIcon from '@mui/icons-material/Home';
import PlaceIcon from '@mui/icons-material/Place';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Chip, IconButton, Paper } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Breadcrumb, { IBreadcrumbItem } from '../../common/components/breadcrumb';
import DataTable from '../../common/components/dataTable';
import { Zone } from '../../common/models/zone';
import { setLoading, setLoadingText } from '../../infra/store/reducers/loading';
import { setZoneId } from '../../infra/store/reducers/router';
import { ZoneService } from './services/zoneService';

const breadcrumbItems: IBreadcrumbItem[] = [
  {
    Icon: <HomeIcon color="secondary" />,
    text: 'Home',
    path: '/'
  },
  {
    Icon: <GpsFixedIcon color="secondary" />,
    text: 'Monitoreo',
    path: '/monitoreo'
  },
  {
    Icon: <PlaceIcon color="primary" />,
    text: 'Zonas',
    path: '/monitoreo/zonas'
  }
];

const Zones = () => {
  const [zones, setZones] = useState<Zone[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const zoneService = new ZoneService();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Zona',
      flex: 2
    },
    { field: 'id', headerName: 'Id de Zona', flex: 1, sortable: true },
    {
      field: 'riskDescription',
      headerName: 'Descripción de Peligro',
      flex: 3
    },
    {
      field: 'riskLevel',
      headerName: 'Nivel de Riesgo',
      sortable: true,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Chip
            label={zoneService.mapRiskLevel(params.row.riskLevel)}
            color={zoneService.mapRiskLevelToChipColor(params.row.riskLevel)}
          />
        );
      }
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () => {
          dispatch(setZoneId(params.row.id));
          navigate(`/monitoreo/zona`);
        };

        return (
          <IconButton onClick={onClick}>
            <VisibilityIcon color="primary" />
          </IconButton>
        );
      }
    }
  ];

  const handleApiResponse = (response: Zone[] | Error) => {
    if (response instanceof Error) throw response;
    setZones(response);
  };

  const handleClearCacheEvent = () => {
    dispatch(setLoading(true));
    dispatch(setLoadingText('Cargando Zonas'));
    zoneService
      .clearCache()
      .then(handleApiResponse)
      .catch(console.error)
      .finally(() => {
        dispatch(setLoading(false));
        dispatch(setLoadingText('Cargando'));
      });
  };

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(setLoadingText('Cargando Zonas'));
    zoneService
      .getZones()
      .then(handleApiResponse)
      .catch(console.error)
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, []);

  return (
    <div className="grid w-full h-full px-2">
      <Breadcrumb items={breadcrumbItems} title="Zonas de Trabajo" />
      <Button
        variant="contained"
        color="error"
        endIcon={<CachedIcon />}
        disableElevation={true}
        onClick={handleClearCacheEvent}
        sx={{ mt: 2 }}
      >
        Borrar Caché
      </Button>
      <Paper elevation={0} sx={{ p: 2, mt: 3 }}>
        <DataTable title="Lista de Zonas" columns={columns} data={zones} />
      </Paper>
    </div>
  );
};
export default Zones;
