import BoyIcon from '@mui/icons-material/Boy';
import { Alert, AlertTitle, Paper, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
const MapInformation = () => {
  return (
    <Paper sx={{ mt: 3, py: 2, px: 2, boxSizing: 'border-box' }} elevation={0}>
      <Typography variant="h6" component="h2">
        <strong>Niveles de Riesgo</strong>
      </Typography>
      <Grid2 container sx={{ my: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} spacing={{ xs: 2, md: 3 }}>
        <Grid2 xs={4}>
          <Alert
            severity="success"
            sx={{ boxSizing: 'border-box', display: 'flex', alignItems: 'center' }}
            variant="outlined"
            icon={<BoyIcon fontSize="large" />}
          >
            <AlertTitle sx={{ m: 0, p: 0 }}>Riesgo mínimo</AlertTitle>
          </Alert>
        </Grid2>
        <Grid2 xs={4}>
          <Alert
            severity="warning"
            sx={{ boxSizing: 'border-box', display: 'flex', alignItems: 'center' }}
            variant="outlined"
            icon={<BoyIcon fontSize="large" />}
          >
            <AlertTitle sx={{ m: 0, p: 0 }}>Riesgo grave</AlertTitle>
          </Alert>
        </Grid2>
        <Grid2 xs={4}>
          <Alert
            severity="error"
            sx={{ boxSizing: 'border-box', display: 'flex', alignItems: 'center' }}
            variant="outlined"
            icon={<BoyIcon fontSize="large" />}
          >
            <AlertTitle sx={{ m: 0, p: 0 }}>SOS o Riesgo intolerable</AlertTitle>
          </Alert>
        </Grid2>
      </Grid2>
    </Paper>
  );
};

export default MapInformation;
