import { Avatar, Box, Paper, Typography } from '@mui/material';
import React from 'react';

import { Machine } from '../../../common/models/machine';

export type MachineInfoProps = {
  machine: Machine;
};

const MachineInfo = ({ machine }: MachineInfoProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        boxSizing: 'border-box',
        display: 'flex',
        justifyItems: 'start',
        justifyContent: 'start',
        width: '100%',
        height: '100%',
        flexDirection: 'column'
      }}
    >
      <Typography variant="h6" component="p">
        <strong>Detalles del equipo</strong>
      </Typography>
      <Box
        component="form"
        noValidate
        sx={{
          boxSizing: 'border-box',
          display: 'flex',
          justifyItems: 'center',
          justifyContent: 'center',
          width: '100%',
          flexDirection: 'column'
        }}
      >
        <Avatar
          src={machine.picture}
          alt="Imagen del equipo"
          sx={{ height: 250, width: 'auto', alignSelf: 'center', mt: 2 }}
        />
        <Typography variant="h6" component="p" sx={{ textAlign: 'center', mt: 2 }}>
          <strong>Equipo - {machine.name} </strong>
        </Typography>
        <Typography variant="caption" component="p" sx={{ textAlign: 'center', mt: 2 }}>
          <strong>Modelo - {machine.type} </strong>
        </Typography>
        <Typography variant="body1" component="p" sx={{ textAlign: 'left', mt: 2 }}>
          {machine.description}
        </Typography>
        {/* <Alert severity="info" sx={{ mt: 2 }}>
          {machine.description}
        </Alert> */}
      </Box>
    </Paper>
  );
};
export default MachineInfo;
